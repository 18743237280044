import Layout from "../../components/layout"
import React from "react"
import * as css from "@components/content.module.css"
import SEO from "../../components/seo"

function SchoolHistory() {
  return (
    <Layout>
      <SEO title="Historia szkoły - ZSP Nidek" />
      <h1>Historia szkoły</h1>
      <div style={{ textAlign: "justify" }}>
        <p className={css.textBlock}>
          Pierwszym nauczycielem mianowanym przez austriackie władze szkolne był
          roku szkolnego 1896/97 Baścik Michał w 1897/98 Serwin Józef, w 1898/99
          Orlef Wacław, w 1899/1900 Probulska, w 1901/04 Gandek, 1904/06
          Kazimierz Józef, w 1906/08 Kanarkówna. Od 1908/09 uczą dwie siły
          nauczycielskie: Konarkówna, nauczycielka kierująca i Świątkowa
          Antonina. W roku 1912 kierownikiem dwuklasowej szkoły w Nidku został
          Kazimierz Łasiński, a dotychczasowa nauczycielka z powodu zamążpójścia
          opuściła Nidek.
        </p>
        <p className={css.textBlock}>
          Od kwietnia 1941 była przerwa w nauczaniu dzieci polskich do 1 V 1943
          z powodu częściowego wysiedlenia miejscowej ludności. Budynek szkoły
          oddano do użytku bauerom, a następnie otwarto w nim naukę dla
          osiedleńców. Władze okupacyjne zezwoliły na otwarcie nauki dla dzieci
          tutejszej gromady, ale tylko w jednej klasie i w budynku wynajętym.
        </p>
        <p className={css.textBlock}>
          Do klasy pierwszej zgłosiło się 39 dzieci, do drugiej 21, do trzeciej
          23, do czwartaj 13. Klasyfikacja z końcem roku wykazała, że do klasy
          drugiej promowano 34, do trzeciej 21. Z ogólnej liczby uczęszczających
          do klasy trzeciej 12 pozostało jako drugoroczne, zaś 11 promowano do
          czwartej. 13 dzieci z powodu przekroczenia wieku szkolnego opuściło
          szkołę. Rok szkolny 1943/44 zakończył się 14.07.1944.
        </p>
        <p className={css.textBlock}>ZAPLECZE SZKOŁY</p>
        <p className={css.textBlock}>
          W szkole działa świetlica dla uczniów, stołówka, biblioteka szkolna i
          spółdzielnia uczniowska. Ponadto koło PCK, Samorząd Uczniowski oraz
          aktywnie pracujący Komitet Rodzicielski organizujący różnego typu
          imprezy dla uczniów i rodziców.
        </p>
        <p className={css.textBlock}>CZY WIESZ, ŻE</p>
        <p className={css.textBlock}>
          Zespół Szkół Samorządowych w Nidku jest spadkobiercą 8-klasowej Szkoły
          Podstawowej im. Mikołaja Kopernika w Nidku, powstałą w oparciu o jej
          bazę oraz kadrę. Placówka ma ugruntowaną dobrą opinię w środowisku
          oraz u władz oświatowych. W ostatnich kilkunastu latach uczniowie
          osiągali dobre wyniki na egzaminach wstępnych do szkół średnich oraz w
          badaniach zewnętrznych prowadzonych przez Małopolskie Kuratorium oraz
          Okręgową Komisję Egzaminacyjną w Krakowie.
        </p>
      </div>
    </Layout>
  )
}

export default SchoolHistory
